import logo from '../img/logo.png';

function NavBar() {

  return (
    <div className="navbar-floating">
      <div className="navbar-container">
        <img src={logo} width={'150px'} alt='De Volta CNH logo' />
        <div className="navbar-buttons-container">

          <a href='https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20De%20Volta%20CNH'>
            <button className="whatsapp">
              <span>Simule em nosso WhatsApp!</span>
              (11) 99400-6268
            </button>
          </a>
          
        </div>
      </div>
    </div>
  );
}

export default NavBar;