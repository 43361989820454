import { useEffect, useState } from 'react';
import HeaderSlider from '../component/header-slider';
import Footer from '../component/footer'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, } from 'mdb-react-ui-kit';
import '../index.css';

import { ChatBox } from '../component/chat';
import { useResponsiveMode } from '../controller/utils';

import { FaThumbsUp, FaUserShield, FaCar, FaBus, FaStar, FaCarSide, FaHandshake, FaMedal } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { FiCheckCircle } from "react-icons/fi";
import { BiSolidCarGarage, BiMessageSquareDetail } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

import NavBar from '../component/navbar';
import CNH from '../img/cnh.png';
import InitialBkg from '../img/initial-bkg.png';
import BkgBike from '../img/bkg-hero1.png';
import BkgCar from '../img/bkg-hero2.png';
import BkgTruck from '../img/bkg-hero3.png';

function Index() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const goToElement = (elementId) => {
        const element = document.getElementById(elementId);
        element.scrollIntoView();
    };

    const slides = [
        {
            bg: InitialBkg, title: `Recupere Sua 
Habilitação com
o De Volta CNH`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgBike, title: `Facilitamos a 
Recuperação 
da Sua CNH!`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgCar, title: `Especialistas em 
Reabilitação 
de Motoristas`, cta_text: '', cta_url: '', cta_url_class: ''
        }, {
            bg: BkgTruck, title: `Volte a Dirigir
com Segurança: 
Confie no De 
Volta CNH`, cta_text: '', cta_url: '', cta_url_class: ''
        },
    ];

    const isMobile = useResponsiveMode();
    const [view, setView] = useState('home');
    const [activeIcon, setActiveIcon] = useState(null);

    const handleIconClick = (icon) => {
        setActiveIcon(icon === activeIcon ? null : icon);
    };
    return (
        <>

            <div>
                <NavBar />

                <MDBContainer id='home' fluid className="headerContainer">
                    <HeaderSlider slides={slides} id='slider' />
                    {/* {!isMobile && (<div id='ia-assist' className='chatbox-container'> <ChatBox setView={setView} /> </div>)} */}

                    <div className='btnSlideDiv'>
                        <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='btnSlide'>Realizar Orçamento</button>
                    </div>

                </MDBContainer>

                <MDBContainer fluid className='bg-default'>
                    <MDBRow className='justify-content-center align-items-center'>
                        <MDBCol md={8} className='container-about-section'>
                            <p>A <span>De Volta CNH</span> é sua solução confiável para a recuperação de sua
                                Carteira Nacional de Habilitação. Com uma equipe especializada e dedicada,
                                oferecemos serviços completos para ajudá-lo a regularizar sua situação e voltar
                                a dirigir com segurança e tranquilidade.</p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer>
                    <div className='circles-holder'>
                        <MDBRow style={{ textAlign: 'center' }} className='justify-content-center align-items-start'>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'reabilitacao' ? 'expanded' : ''}`} onClick={() => handleIconClick('reabilitacao')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <FaCar size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>REABILITAÇÃO</h4>
                                    <div className='content'>
                                        <p>Reabilite sua CNH com especialistas. Volta CNH oferece suporte completo para recuperar sua habilitação. Comece sua reabilitação hoje!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'orientacao' ? 'expanded' : ''}`} onClick={() => handleIconClick('orientacao')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <FaBus size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>ORIENTAÇÃO</h4>
                                    <div className='content'>
                                        <p>Orientação profissional para recuperar sua CNH. Volta CNH guia você em cada passo. Receba a melhor orientação agora!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'regularizacao' ? 'expanded' : ''}`} onClick={() => handleIconClick('regularizacao')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <FaStar size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>REGULARIZAÇÃO</h4>
                                    <div className='content'>
                                        <p>Regularize sua CNH com eficiência. Volta CNH resolve todas as pendências para você. Regularize sua CNH hoje mesmo!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'agilidade' ? 'expanded' : ''}`} onClick={() => handleIconClick('agilidade')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <FaCarSide size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>AGILIDADE</h4>
                                    <div className='content'>
                                        <p>Agilidade no processo de recuperação da CNH. Volta CNH oferece serviços rápidos e eficazes. Experimente nossa agilidade hoje!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'seguranca' ? 'expanded' : ''}`} onClick={() => handleIconClick('seguranca')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <BiSolidCarGarage size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>SEGURANÇA</h4>
                                    <div className='content'>
                                        <p>Confiança e segurança na recuperação da sua CNH. Volta CNH garante um processo tranquilo e seguro. Confie na nossa expertise!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className={`icon-container ${activeIcon === 'suporte' ? 'expanded' : ''}`} onClick={() => handleIconClick('suporte')}>
                                    <span className='icon-span mt-3 mb-3'>
                                        <GiCarWheel size={'3rem'} color='#f5f5f5' />
                                    </span>
                                    <h4>SUPORTE</h4>
                                    <div className='content'>
                                        <p>Suporte completo para recuperar sua CNH. Volta CNH está ao seu lado em cada etapa. Obtenha o suporte que você precisa hoje!</p>
                                        <button onClick={() => window.open('https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20Volta%20CNH', '_blank')}>
                                            Faça uma cotação
                                        </button>
                                    </div>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </MDBContainer>
                <MDBContainer id='vantagens' fluid className='vantagens-container'>
                    <MDBRow className='justify-content-center align-items-center'>
                        <MDBCol md={6}>
                            <h1 className='vantagens-title'>
                                <span>Vantagens</span> da <span>De Volta CNH</span>
                            </h1>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className='cardsHolder justify-content-center align-items-center'>
                        <MDBCol md='6'>
                            <MDBCard className='cardsProtec'>
                                <MDBCardBody>
                                    <div className='icon-services-container'><FaMedal style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                    <h3>
                                        Serviços Abrangentes:
                                    </h3>
                                    <p>
                                        Oferecemos uma ampla gama de serviços, incluindo orientação jurídica, acompanhamento em processos
                                        administrativos, regularização de multas e pontos, e muito mais, para atender às necessidades
                                        específicas de cada cliente
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCard className='cardsProtec'>
                                <MDBCardBody>
                                    <div className='icon-services-container'><FiCheckCircle style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                    <h3>
                                        Atendimento Personalizado:
                                    </h3>
                                    <p>
                                        Nossa equipe está comprometida em fornecer um atendimento personalizado,
                                        compreendendo a situação de cada cliente e oferecendo soluções adequadas
                                        para garantir uma recuperação rápida e eficiente da sua CNH.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCard className='cardsProtec'>
                                <MDBCardBody>
                                    <div className='icon-services-container'><FaThumbsUp style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                    <h3>
                                        Eficiência e Agilidade:
                                    </h3>
                                    <p>
                                        Priorizamos a eficiência e a agilidade em todos os nossos processos, garantindo
                                        que você obtenha os resultados desejados no menor tempo possível.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCard className='cardsProtec'>
                                <MDBCardBody>
                                    <div className='icon-services-container'><FaHandshake style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                    <h3>
                                        Confiança e Credibilidade:
                                    </h3>
                                    <p>
                                        Com anos de experiência e um histórico comprovado de sucesso, somos reconhecidos
                                        como especialistas confiáveis na recuperação de habilitações em todo o Brasil.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='6'>
                            <MDBCard className='cardsProtec'>
                                <MDBCardBody>
                                    <div className='icon-services-container'><FaUserShield style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                    <h3>
                                        Compromisso com a Excelência:
                                    </h3>
                                    <p>
                                        Nosso compromisso é fornecer serviços de excelência, superando as expectativas dos nossos
                                        clientes e garantindo sua total
                                        satisfação em cada etapa do processo de recuperação da sua CNH.
                                    </p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md='7' className='justify-content-center align-items-center py-4 d-flex'>
                            <button id='btn-cta-cotacao_vantagens' onClick={() => goToElement('contato')} className='contactBtn vantagens'>Solicitar Revisão</button>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer>
                    <MDBRow className='placas-mercosul justify-content-center align-items-center'>
                        <MDBCol md={6}>
                            <img src={CNH} alt='Placas Mercosul' id='img_reclame_aqui' />
                        </MDBCol>
                        <MDBCol md={5}>
                            <h4>O Diferencial da De Volta CNH</h4>
                            <p>A De Volta CNH se destaca pela eficiência na
                                recuperação de Carteira Nacional de Habilitação,
                                oferecendo atendimento personalizado e suporte
                                jurídico especializado. Com anos de experiência
                                e um histórico de sucesso, garantimos soluções
                                rápidas e eficazes, proporcionando confiança e
                                credibilidade aos nossos clientes.</p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer fluid className='contato-container' id='contato'>
                    <MDBRow className='justify-content-center align-items-center'>
                        <MDBCol md={4} className='text-center contact-col'>
                            <div className='contact-container'>
                                <span className='contact-icon'><BiMessageSquareDetail /></span>
                                <h4>Entre em contato agora mesmo!</h4>

                                <a href='https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20De%20Volta%20CNH'>
                                    <button className="contactBtn whatsapp">
                                        <span>Entre em contato!</span>
                                        (11) 99400-6268
                                    </button>
                                </a>
                                {/* <button id='btn-cta-cotacao' onClick={() => goToElement('contato')} className='contactBtn'>Clique aqui e faça uma Revisao!</button> */}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <Footer />
                <a id='whatsappFloat' href="https://wa.me/5511994006268?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20De%20Volta%20CNH" rel="noreferrer" class="whatsapp-float hover-shadow" target="_blank">
                    <FaWhatsapp />
                </a>
            </div>
        </>
    )
}

export default Index;